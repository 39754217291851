@import "mixins";

.DesktopMenu {
    @include flexList;
    align-items: center;
    margin-left: 30px;
    ;

    >div {
        margin-right: 10px;

    }
}

.ClosedItems {
    border: 0.5px solid #B5BCCB;
    border-radius: 19px;
    height: 38px;
    @include flexList;
    align-items: center;

    // margin-left: 30px;
    padding-left: 5px;
    >div {
        margin-right: 0px;

    }

    >div:last-child{
        margin-right: 10px;
    }



}