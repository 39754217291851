.ProductProps {
    box-sizing: border-box;
    // width: 756px;
    width: 100%;
    min-height: 150px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px;

    h3{
        margin-top: 0;
        margin-bottom: 30px;;
    }
}

.Props {
    display: flex;
    flex-wrap: wrap;

    .Prop {
        width: 176px;
        margin-bottom: 25px;;

        .PropName {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #747F94;
            margin-bottom: 8px;;
        }

        .PropValue {
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: #1D1D1B;
        }
    }
}