.ProductCard {
    width: 100%;
}

.Gallery {
    margin-bottom: 30px;
    
}


.Props, .Description{
    margin-bottom: 20px;   
}