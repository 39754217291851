@import "color-palette";

.privacy-info {
  font-size: 10px;
  margin-top: 24px;
  color: $color-text-accent;
  text-align: center;

  &__link {
    color: $color-text-aqua;
    text-decoration: underline;
  }
}