.ProductDescription {
    box-sizing: border-box;
    // max-width: 756px;
    width: 100%;
    min-height: 194px;
    background: #FFFFFF;
    border-radius: 8px;
    padding: 25px 25px 30px 25px;

    h3 {
        margin-top: 0;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1D1D1B;
        margin-bottom: 20px;
    }

    a {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #3B85F7;
    }

    p {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;

        color: #272727;


        display: -webkit-box;
        display: -moz-box;
        display: -ms-box;
        display: box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        -webkit-transition: max-height 0.8s;
        -moz-transition: max-height 0.8s;
        transition: max-height 0.8s;

        &.Expanded {
            // transition: max-height .5s;
            -webkit-line-clamp: unset;
        }
    }
}