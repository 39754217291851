@import "mixins";
@import "layout-vars";
@import "zIndexes";

.MobileMenu {
    margin-left: 36px;
    position: relative;
    height: 50px;
    border-bottom: 2px solid #3B85F7;
    min-width: 68px;

    display: flex;
    align-items: center;
    justify-content: flex-end;

    >span {
        cursor: pointer;
        height: 100%;
        ;
        display: flex;
        align-items: center;
    }


    .ButtonContainer {
        display: flex;
        align-items: center;
        position: relative;
        top: 2px;

        span {
            padding-right: 4px;
            padding-left: 8px;
            font-style: normal;
            font-weight: 600;
            font-size: 13px;
            line-height: 16px;
            color: #3B85F7;

            &:hover {
                text-decoration: none;
            }
        }
    }


}


.Icon {
    position: absolute;
    right: -27px;
    top: 12px;
}

.ExpandedMenuCont {
    position: absolute;
    top: 60px;
    left: -7px;

    z-index: $mobileMenuZIndex;
    background: white;
    padding: 10px 0;

    background: #FFFFFF;
    border: 0.5px solid #E2E5EB;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.07);
    border-radius: 8px;


}

.ExpandedMenu {
    @include list;

    .Li {
        cursor: pointer;
        width: 100%;
        ;

        &:hover {
            background: #F6F8FB;
        }

        &:hover {

            a {
                text-decoration: none;
                ;

                >span {
                    text-decoration: none;
                    color: #3B85F7;
                }

                >svg {
                    path {
                        fill: #3B85F7;
                    }

                }
            }

        }

        &.Active {
            background: #F6F8FB;

            a {
                color: #3B85F7;
            }

            svg {
                fill: #3B85F7
            }
        }

        height: 40px;
        width: 200px;
        padding-left: 20px;
        display: flex;
        align-items: center;
    }

}

@media (max-width: calc($headerMobileBP + 40px)) {
    .MobileMenu {
        margin-left: 20px;
    }
}

@media (max-width: $headerMobileBP) {
    .MobileMenu {
        margin-left: 0px;
    }
}


.Disabled {
    border: 0.5px solid #B5BCCB;
    border-radius: 19px;
    margin: 0 6px;
    // width: 171px;
    position: relative;


    .Li {
        padding-left: 14px;

        &:hover {
            background: none;
        }

    }


    .Li {
        cursor: pointer;
        width: 100%;
        ;

        &:hover {
            background: none;
        }

        &:hover {

            a {
                text-decoration: none;
                ;

                >span {
                    text-decoration: none;
                    color: #818BA1;
                }

                >svg {
                    path {
                        fill: #818BA1;
                    }

                }
            }

        }

        &.Active {
            background: #F6F8FB;

            a {
                color: #3B85F7;
            }

            svg {
                fill: #3B85F7
            }
        }

        height: 40px;
        width: 200px;
        padding-left: 20px;
        display: flex;
        align-items: center;
    }


    .Soon {
        position: absolute;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */


        color: #FFFFFF;

        background: #DA3832;
        border-radius: 50px;

        top: -10px;
        right: 0;
        padding: 3px 6px;
    }

}