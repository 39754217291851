.TextInput {
    input {
        height: 36px;
        padding: 10px 13px;
        border: 1px solid #B5BCCB;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        // color: #7B869B;
        color: black;
        // width: inherit;
        width: 100%;

    }

    input:disabled {
    }

}