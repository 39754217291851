@import "color-palette";

.productShare {
  width: 30px;
  height: 30px;
  border-radius: 6px;
  position: relative;

  &__button {
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    border-radius: 6px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: $color-bg-grey-light;
    }
  }

  &__popover {
    background-color: $color-bg-white;
    border: 0.5px solid $color-border-light;
    border-radius: 8px;
    bottom: -56px;

    display: flex;
    position: absolute;
    transform: translateX(-92px);
    overflow: hidden;
    box-shadow: 0 6px 30px 0 $shadow-light-07;
    z-index: 100;

    &-item {
      width: 40px;
      height: 40px;
      background-color: $color-bg-white;

      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:hover {
        background-color: $color-bg-ghost-white;
      }
    }
  }

  &__copied {
      svg {
        path {
          fill: $color-active-green;
        }
      }
  }
}