@import "mixins";
.CardsList{
    > ul{
        @include list;
    }
}


.Vertical {
    display: inline-grid;
    width:100%;
    justify-content: center;
    align-items: center;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 930px) {
        grid-template-columns: repeat(3, 1fr);
    }
    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 520px) {
        grid-template-columns: repeat(1, 1fr);
    }
    gap: 16px;
    >li {
        width: 100%;
        min-width: 0;
        //
    }
}


.PrimaryButton {
    margin: 40px auto 0 auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 10px;
    width: 220px;
    height: 56px;
    border: 1px solid #3B85F7;
    border-radius: 10px;
    background-color: transparent;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18px;

    color: #3B85F7;


}