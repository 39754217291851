@import 'zIndexes';
@import 'color-palette';

.Gallery {
    width: 100%;
}

.GalleryCont {
    width: 100%;
    max-height: 500px;
}

.Prev {
    left: 16px;
    top: calc(50% - 16px);
}

.Next {
    right: 16px;
    top: calc(50% - 16px);
}

.Prev,
.Next {
    position: absolute;
    z-index: $galleryButtonsZIndex;
    cursor: pointer;
}

.Thumbnails {
    margin-top: 16px;
    display: flex;
    justify-content: center;
    gap: 15px;

    &__item {
        max-width: 140px;
        width: 100%;
        height: 100px;
        background: #EFF3F8;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        cursor: pointer;
    }

    &__layer {
        position: absolute;
        background: rgba(29, 29, 27, 0.69);
        max-width: 140px;
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 6px;
        cursor: pointer;

        &--label {
            cursor: pointer;
            display: block;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #FFFFFF;
        }
    }


    &__image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
        cursor: pointer;
    }
}

.FullscreenImageIndex {
    position: absolute;
    width: calc(100% - 45px);
    display: flex;
    justify-content: space-between;
    margin: 20px;

    .ImageIndex {
        padding: 10px 20px;
        background: rgba(29, 29, 27, 0.69);
        color: white;
        border-radius: 5px;
        font-weight: 600;
    }

   

}

.PrevBtn,
.NextBtn {
    z-index: 9999;
    cursor: pointer;
}



.CloseIconCont{
    position: relative;
    width: 32px;
    height: 32px;
}
.CloseIcon {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .CloseIcon:hover {
    opacity: 1;
  }
  .CloseIcon:before, .CloseIcon:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .CloseIcon:before {
    transform: rotate(45deg);
  }
  .CloseIcon:after {
    transform: rotate(-45deg);
  }