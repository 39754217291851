@import "mixins";
@import "color-palette";

.Template{
    position: relative;
    width: 100%;
}

.Container {
    display: flow-root;
    background-color: transparent;

    &__blue {
        background-color: $color-bg-blue-light;
    }

    &__white {
        background-color: $color-bg-white;
    }
}