.RestorePassForm {
    max-width: 400px;
    width: 100%;
    padding: 18px 40px;
    @media screen and (max-width: 400px) {
        padding: 12px 20px;
    }
    border-radius: 10px;
    background: white;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
    h3 {
        padding: 0;
        margin: 15px 0 20px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;

        color: #1d1d1b;
    }
}

.Error {
    color: rgb(197, 49, 49);
    font-size: 12px;
    margin-top: 5px;

    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}
.InputCont {
    width: 100%;
    margin-bottom: 15px;

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: #26365c;
        margin-bottom: 5px;
        display: block;
    }
}

.Link {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    /* identical to box height */

    text-align: center;

    color: #3b85f7;
    margin: 12px auto;
    width: 100%;
    display: flex;
    justify-content: center;
}

.Text {
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    text-align: center;

    color: #1d1d1b;
    margin-top: 0;
    margin-bottom: 30px;;
}
