@import "zIndexes";
@import "mixins";

.UserMenu {
    width: 172px;
    height: 160px;

    background: #FFFFFF;
    border: 0.5px solid #E2E5EB;
    box-shadow: 0px 6px 30px rgba(0, 0, 0, 0.07);
    border-radius: 0px 0px 8px 8px;

    position: absolute;
    top: 43px;
    left: -5px;
    z-index: $layoutUserMenuZIndex;
    overflow: hidden;

    ul {
        @include list;
        a{
            text-decoration: none;
        }

        li {
            @include noselect;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 16px;
            height: 40px;

            &:hover{
                background: #F6F8FB;
                cursor: pointer;
            }

            a {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                color: #1D1D1B;
                &:hover{
                    text-decoration: none;;
                }
            }
        }
    }




}