@import "mixins";
@import "layout-vars";
@import "color-palette";

.Container {
    @include container;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $headerHeight;
}

.Header {
    height: $headerHeight;
    background: #FFFFFF;
}

.Logo {
    font-weight: 700;
    font-size: 13px;
    line-height: 16px;

    &:hover {
        text-decoration: none;
    }

    >div {
        width: 100px;
        height: 36px;
        background: #EFF3F8;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }
}

.Menu,
.UserMenu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
}

.Menu {
    li {
        margin-right: 28px;
        ;
    }
}

.LeftPart,
.RightPart {
    display: flex;
    align-items: center;
}

.LeftPart {
    // overflow: hidden;
}

.UserMenu {
    align-items: center;
    position: relative;

    &__items {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        margin-right: 20px;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0;
        position: relative;
        cursor: pointer;
    }
}

.IconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.CompareIcon {
    width: 24px;
    height: 24px;
    fill: $color-primary-black;

    &__badge {
        width: 22px;
        max-width: 24px;
        height: 22px;
        background-color: $color-bg-red;
        border: 2px solid $color-bg-white;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $color-text-white;
        font-size: 12px;
        font-weight: 600;
        position: absolute;
        top: -5px;
        right: -8px;
    }
}


.MenuButton {
    width: 100%;
    @include noselect;
    cursor: pointer;
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    color: #1D1D1B;

    display: flex;
    align-items: center;

    >span {
        padding-left: 9px;
    }

    >svg {
        position: relative;
        top: -1px;
    }

    &:hover {
        text-decoration: none;

        >span {
            // text-decoration: underline;
        }

    }
}

.Divider {
    width: 1px;
    height: 30px;
    border-left: 0.5px solid #B5BCCB;
    margin: 0 20px;
}


.Active {
    // font-weight: bold;
}


$menuWidth: 972px;

.DesktopMenu {
    @media (max-width: $menuWidth) {
        display: none !important;
    }

    a,
    a:hover {
        text-decoration: none;
    }

}

.MobileMenu {
    @media (min-width: $menuWidth) {
        display: none !important;
    }
}

.PrimaryButton {
    $widthToMinimize: 1150px;
    cursor: pointer;
    background: #3B85F7;
    padding: 10px 15px;

    @media (max-width: $widthToMinimize) {
        padding: 10px;
        ;
    }

    border: none;
    color: white;

    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: .03em;

    border-radius: 6px;
    display: flex;

    >svg {
        margin-right: 6px;

        @media (max-width: $widthToMinimize) {
            margin-right: 0;
        }
    }

    >span {
        @media (max-width: $widthToMinimize) {
            display: none;
        }
    }
}
#EnterButton {
    width: unset;
}
@media (max-width: $headerMobileBP) {
    #EnterButton {
    
        >span {
            display: none;
        }
    }


    .ButtonContainer {}

    .Logo {
        display: none;

        >div {
            width: 50px;
        }
    }

    .UserMenuButton {
        >span {
            display: none;
        }
    }

}


.Disabled{
    color:  #818BA1;
}


.IconButton{
    width: 100%;
}