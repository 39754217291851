.CardChart {
    background: white;
    padding: 25px;
    border-radius: 8px;
    position: relative;

    h3 {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #1d1d1b;
        margin: 0 0 23px 0;
        @media screen and (max-width: 500px) {
            margin-bottom: 10px;
            
        }
    }

    p {
        margin-bottom: 40px;

        color: #272727;
        font-size: 15px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        @media (max-width: 500px) {
            margin-top: 0px;
            margin-bottom: 10px;
        }

    }

    text:has(.recharts-label) {
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        color: #1d1d1b;
    }
}

.yLabel {
    transform: rotate(-90deg);
    color: #747f94;

    text-align: right;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.Legend {
    margin-left: 65px;
    
    display: flex;

    @media (max-width: 500px) {
        margin-left: 0;
        gap: 20px;
        justify-content: space-between;;
    }


    color: #1d1d1b;

    text-align: right;
    leading-trim: both;

    text-edge: cap;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    > div {
        margin-right: 2em;
        @media (max-width: 500px) {
            margin-right: 0;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
    }


    
}

.BlueLine {
    width: 25px;
    height: 2px;
    background: #3b85f7;
}

.GreenLine {
    width: 25px;
    height: 2px;
    background: #51c463;
}

.Tooltip {
    padding: 16px;
    border-radius: 8px;
    border: 0.5px solid #e2e5eb;

    background: #fff;

    box-shadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.07);

    > div:nth-child(1) {
        color: #1d1d1b;

        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    > div:nth-child(2) {
        color: #1d1d1b;

        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.Header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: center;

        // wide select input
        > div {
            margin-bottom: 1em;
            width: 100%;
            > div {
                width: 100%;
            }
        }
    }
}
