@import "color-palette";

.product-price {
  display: flex;
  flex-direction: row;
  align-items: center;

  &__inner {
    min-width: 60px;
    &-hidden {
      min-width: 60px;
      height: 14px;
      background-color: $color-text-accent;
      opacity: .15;
      border-radius: 4px;
    }
  }
}

.update-button {
  position: relative;
  background-color: $color-bg-blue;
  border: none;
  width: 24px;
  height: 24px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  cursor: pointer;

  &__icon {
    display: block;
    width: 18px;
    height: 18px;

    &-rotate {
      animation: rotate 0.5s linear;
    }
  }
}

.update-button:before {
  content: '';
  position: absolute;
  top: -12px;
  left: -12px;
  bottom: -12px;
  right: -12px;
  border-radius: 50%;
  background: transparent;
  transform-origin: center;
  z-index: -1;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}