.PrimaryButton {
    width: 100%;
    ;
    cursor: pointer;
    background: #3B85F7;
    padding: 10px 20px;
    border: none;
    color: white;

    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 16px;
    letter-spacing: .03em;

    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    >svg {
        margin-right: 6px;
    }


    &.Large {
        background: '#3B85F7';
        border-radius: 10px;
        height: 56px;

        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 22px;
        text-align: center;
        color: #FFFFFF;
    }

    &.Primary {
        background: transparent;
        border: 1px solid #3B85F7;
        color: #3B85F7;
        border-radius: 10px;
    }

    &.Light {
        background: transparent;
        border: 1px solid #3B85F7;
        color: #3B85F7;
    }
}

.Loading{
    opacity: .5;
}

@-webkit-keyframes spin {
	0%   {-webkit-transform: rotate(0deg);}

	100% {-webkit-transform: rotate(360deg);}
}

@keyframes spin {
	0%   {transform: rotate(0deg);}

	100% {transform: rotate(360deg);}
}

.Loader{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-top-color: #3B85F7;
    animation: spin 1s linear infinite;
    margin-right: .5em;
}