@import "mixins";

.AvailableSoon {
    background: white;
    border-radius: 10px;
    padding: 30px 70px;
    
    @media (max-width: 600px) {
        padding: 30px 30px;
    }
    display: flex;
    align-items: center;
    flex-direction: column;


    label {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;

        color: #1D1D1B;
        margin-bottom: 7px;
    }

    p {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        text-align: center;

        color: #272727;
        margin-bottom: 40px;
    }

    ul {
        
        @include flexList;
        width: 100%;
        justify-content: space-between;

        li {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;

            span {
                font-style: normal;
                font-weight: 600;
                font-size: 13px;
                line-height: 16px;
                /* identical to box height */

                text-align: center;

                color: #000000;
                margin-top: 10px;
            }
        }
    }
}