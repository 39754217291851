@import "color-palette";

.HoverGallery {
    overflow: hidden;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: relative;
    cursor: pointer;

    &__wrapper {
        position: relative;
        overflow: hidden;
        background: $color-bg-smoky-white;
        width: 100%;
        height: 100%;
    }

    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
        color: transparent;
        object-fit: cover;

        &--hidden {
            display: none;
        }
    }
}

.Indicator {
    position: absolute;
    bottom: 13px;
    display: flex;
    
    .IndicatorBar {
        width: 37px;
        height: 3px;
        background: $color-bg-indicator;
        border-radius: 50px;

        &.Active{
            background: $color-bg-black;
        }
    }
}