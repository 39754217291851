@import "color-palette";
.TextInput {
    textarea {
        padding: 10px 13px;
        border: 1px solid $color-divider-border;
        border-radius: 6px;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $color-blackout;
        width: 100%;

    }
}