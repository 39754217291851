.LocationSnippet {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #747F94;
    display: flex;
    align-items: center;

    >svg {
        margin-right: 8px;
    }
}