@import "zIndexes";
.MobileSelectInput {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: white;
    z-index: $mobileFullScreenWindow;




    h3 {
        padding: 0;
        margin: 15px 0 10px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 29px;
        text-align: center;

        color: #1d1d1b;
    }

    li.Selected {
        background: #f6f8fb;
        font-weight: 700;
    }
}

.CloseBtn {
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.OptionsList {
    margin-left: 0;
    list-style: none;
    padding: 0;
    // height: 200px;
    // min-height: 200px;
    // overflow: scroll;
    margin-bottom: 0;
    margin-top: 0;
    // border-bottom: 0.5px solid #E2E5EB;

    ul {
        margin: 0;
        padding: 0;
        margin-bottom: 2em;
    }

    li.GroupName {
        > label {
            color: gray;
            font-size: 16px;
            padding-left: 21px;
            text-transform: uppercase;
        }
    }

    li.Option {
        padding: 15px 16px 15px 35px;
        display: flex;
        flex-direction: column;
        // align-items: center;
        padding-left: 42px;
        &:hover {
            background: #f6f8fb;
        }
        cursor: pointer;
        min-height: 40px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #26365c;

        border-bottom: 0.5px solid #e2e5eb;
    }

    li.Active {
        background: #f6f8fb;
    }

    li.Selected {
        background: #f6f8fb;
        font-weight: 700;
    }
}

.Content {
    overflow: scroll;
    // -webkit-overflow-scrolling: touch;
    height: calc(100vh - 90px);
}

.Search {
    position: relative;
    width: 100%;
    input {
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 29px;
        padding-left: 25px;
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #b5bccb;
    }
    padding: 0 16px;

    margin: 0 0 15px;

    svg {
        position: absolute;
        right: 22px;
        top: 5px;
    }
}


.NoResults{
    display: none;;
    &:nth-child(1){
        display: block;
        width: 100%;;
        text-align: center;
    }
}