@import "color-palette";

.CallbackFrom {
    position: relative;
    max-width: 400px;
    width: 100%;
    padding: 18px 40px;
    
    @media screen and (max-width: 400px) {
        padding: 12px 20px;
    }

    border-radius: 10px;
    background: $color-active-white;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);

    h3 {
        padding: 0;
        margin: 15px 0 35px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color-bg-black;
    }
}

.CloseBtn{
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
}

.InputCont {
    width: 100%;
    margin-bottom: 15px;

    label {
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
        color: $color-text-blue;
        margin-bottom: 5px;
        display: block;
    }
}

.InputContRequired {
    position: relative;

    &::before {
        content: "";
        width: 6px;
        height: 6px;
        background: $color-text-red;
        display: block;
        position: absolute;
        left: -15px;
        bottom: 14px;
        border-radius: 3px;
    }
}

.Error {
    color: $color-text-red;
    font-size: 12px;
    margin-top: 5px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}