.Svg {

    &:hover,
    &.Active {
        rect.Highlight {
            fill: #3B85F7;
        }
    }




}


.FavoriteBtn {}



.HeartBtn {
    .HeartActive {
        display: block;
    }

    .HeartNotActive {
        display: none;

    }

    &:hover, &.Selected {
        .HeartActive {
            display: none;
        }

        .HeartNotActive {
            display: block;

        }
    }
}