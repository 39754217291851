.TechnicalPause {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    background: white;
}

.Header {
    font-size: 18px;
    font-weight: 500;
    margin: 10px;
    text-align: center;
}

.P {
    font-size: 13px;
    margin: 0;
    font-weight: 500;
    text-align: center;
}
