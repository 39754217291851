@import "layout-vars";

.wrapper {
  position: relative;
  width: 274px;
  height: 482px;

  @media (max-width: $twoColumnsProductCardBP) {
    width: auto;
  }
}

.wrapperCard {
  position: relative;
  width: 366px;
  height: 480px;
  margin-bottom: 19px;

  @media (max-width: $twoColumnsProductCardBP) {
    margin-bottom: 10px;
  }

  @media (max-width: $mobileSize) {
    height: 494px;
    padding-bottom: 0px;
    box-shadow: none;
    width: fit-content;
  }

  @media (max-width: $headerMobileBP) {
    width: fit-content;
  }
}

.wrapperHorizontal {
  position: relative;
  width: 300px;
  height: 300px;
  margin: 6px 20px;
}

.img {
  border-radius: 12px;
  width: 100%;
  height: 100%;
  
  @media (max-width: $mobileSize) {
    width: 100%;
    height: 100%;
  }
}
