@import "mixins";
@import "layout-vars";

.BreadCrumbs {

    ul {
        @include flexList;
        align-items: center;

        li {
            display: flex;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: #747F94;

            >svg {
                margin: 0 12px;
            }
        }

        li:last-child {
            a {
                text-decoration: none;
                cursor: default;
            }

        }
    }

}


@media (max-width: $headerMobileBP) {
    .BreadCrumbs {
        ul {
            li {
                font-size: 10px;
                line-height: 13px;

                >svg {
                    margin: 0 6px 0 0;
                }
            }
        }
    }

}