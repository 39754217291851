@import "color-palette";

.CodeInput {
    display: flex;
    flex-direction: row;
    column-gap: 10px;

    &__inner {
        width: 40px;
        height: 56px;
        border: 1px solid $color-divider-border;
        border-radius: 6px;

        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__input {
        padding: 0;
        width: 30px;
        height: 90%;
        border: none;
        outline: none;
        text-align: center;
        font-size: 24px;
        font-weight: 600;
        color: $color-text-blue;

        &::placeholder {
            color: $color-input-accent;
            text-align: center;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    &__divider {
        width: 20px;
        height: 1px;
        background: $color-bg-accent;
        position: relative;
        top: 27px;
    }
}