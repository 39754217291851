@import "color-palette";

.EnterByPhoneForm {
    max-width: 400px;
    width: 100%;
    padding: 18px 40px;
    position: relative;
    border-radius: 10px;
    background: $color-bg-white;
    box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);

    &__title {
        padding: 0;
        margin: 15px 0 35px 0;
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: $color-primary-black;
    }

    &__input-phone {
        width: 100%;
        margin-bottom: 15px;

        &--text {
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 16px;
            color: $color-text-blue;
            margin-bottom: 5px;
            display: block;
        }
    }

    &__button {
        height: 56px;
        font-size: 18px;
        margin: 22px 0 18px 0;
    }

    &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }

    &__email-mode {
        color: $color-text-aqua;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        margin: 12px auto;
        width: 100%;
        display: flex;
        justify-content: center;
        cursor: pointer;
    }

    &__container, &__sent-text {
        font-size: 13px;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;
        margin-bottom: 2em;
    }

    &__sent-text {
        width: 100%;
        display: block;
        margin-bottom: 10px;
    }

    &__error, &__error-code {
        color: $color-text-red;
        font-size: 12px;
        margin-top: 5px;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
    }

    &__error-code {
        margin: 10px 0;
        text-align: center;
    }

    @media screen and (max-width: 400px) {
        padding: 12px 20px;
    }
}