@import "color-palette";

.product-location {
    background: $color-bg-white;
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 20px;;

    &__title {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: $color-primary-black;
        margin: 0 0 23px 0;
    }

    &__name {
        display: flex;
        gap: 9px;
        color: $color-primary-black;
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        margin-bottom: 16px;
    }

    &__map {
        width: 100%;
        height: 400px;
    }
}