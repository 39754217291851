.ProductChartLine {
    border-radius: 12px;
    border: 1px solid rgba(181, 188, 203, 0.38);

    background: #fff;

    height: 80px;

    display: flex;
    width: 100%;

    padding: 10px 0;
}

.Col {
    flex-grow: 1;
}

.Col:nth-child(1) {
    $baseWidth: 335px;
    $gap: 25px;
    max-width: calc($baseWidth + $gap);
    padding: 0 20px;
    display: flex;
    justify-content: center;

    > div {
        margin: 0;
        margin-left: $gap;
        display: flex;
        align-items: center;

        > span {
            margin: 0;
            padding: 0;
            border-radius: 8px;
            background: rgba(59, 133, 247, 0.05);
            padding: 8px;

            color: #1d1d1b;

            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }

    // Bias not displayed
    @media (max-width: 610px) {
        max-width: 200px;
        h3 {
            // display: none;
        }
        > div {
            margin-left: 0;
        }
    }

    @media (max-width: 480px) {
        display: none;
    }
}

.BiasDisplayed {
    .Col:nth-child(1) {
        @media (max-width: 610px) {
            max-width: 210px;
            h3 {
                display: none;
            }
            > div {
                margin-left: 0;
            }
        }

        @media (max-width: 480px) {
            display: none;
        }
    }
}

.Col:nth-child(2) {
    padding: 0 20px;
    border-left: solid 0.5px #b5bccb;
    border-right: solid 0.5px #b5bccb;

    @media (max-width: 480px) {
        border: none;
    }
}

.Col:nth-child(3) {
    @media (max-width: 940px) {
        display: none;
    }

    padding: 0 20px;
    max-width: 300px;
    display: flex;
    align-items: center;
    p {
        color: #1d1d1b;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
}

.SecondCol {
    .HeaderTexts {
        width: 100%;
        display: flex;
        justify-content: space-between;

        margin-bottom: 10px;
        > span {
            color: #747f94;

            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}

.ProgressBar {
    position: relative;

    .BGLine {
        position: absolute;
        border-radius: 100px;
        background: rgba(222, 233, 227, 0.62);
        width: 100%;
        height: 10px;
    }

    $width: 60%;

    .GreenLine {
        position: absolute;
        border-radius: 100px;
        background: #51c463;
        width: $width;
        height: 10px;
        left: calc(50% - calc($width / 2));
    }

    .TextFrom {
        top: 20px;
        width: 140px;
        color: #1d1d1b;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        position: absolute;
        left: calc(calc(50% - calc($width / 2)) - 70px);
        text-align: center;
    }

    .TextTo {
        top: 20px;
        width: 140px;
        color: #1d1d1b;
        font-size: 13px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;

        position: absolute;
        left: calc(calc(50% + calc($width / 2)) - 70px);
        text-align: center;
    }

    .SeparatorFrom,
    .SeparatorTo {
        width: 0.5px;
        height: 8px;
        background: #b5bccb;
        position: absolute;
        top: 12px;
    }

    .SeparatorFrom {
        left: calc(50% - calc($width / 2));
    }

    .SeparatorTo {
        left: calc(50% + calc($width / 2));
    }

    .Cursor {
        z-index: 9999;
        position: absolute;
        top: -8px;
        // left: 45%;
    }
}
