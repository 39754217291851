@import "mixins";
@import "color-palette";

.footer {
    height: 100%;
    background: $color-bg-footer;
    color: $color-text-white;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    padding: 60px 0 45px 0;
    box-sizing: border-box;

    @media (max-width: 900px) {
        padding: 40px 0 25px 0;
    }

    &__wrapper {
        @include container;
        display: flex;
        flex-direction: column;
        gap: 46px;
    }

    &__inner {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 20px;

        @media (max-width: 900px) {
            margin: 0 70px;
        }

        @media (max-width: 550px) {
            flex-direction: column;
            align-items: center;
            gap: 30px;
            margin: 0;
        }
    }

    &__logo {
        display: flex;
        flex-direction: column;
        gap: 10px;

        @media (max-width: 900px) {
            width: 100%;
            margin-bottom: 20px;
            align-items: center;
        }

        @media (max-width: 550px) {
            margin-bottom: 10px;
        }
    }

    &__slogan {
        font-size: 11px;
        line-height: 13px;
        font-weight: 600;
        max-width: 205px;
    }

    &__column {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @media (max-width: 550px) {
            width: 100%;
            max-width: 270px;
        }
    }

    &__label {
        font-size: 13px;
        line-height: 16px;
    }

    &__list {
        @include list;
        display: flex;
        flex-direction: column;
        gap: 2px;
    }

    &__bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 40px;

        @media (max-width: 430px) {
            flex-direction: column;
            gap: 20px;
        }
    }

    &__copyright {
        font-size: 11px;
        line-height: 13px;
    }

    &__socials {
        display: flex;
        flex-direction: row;
        gap: 10px;

        &--link {
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        @media (max-width: 1400px) {
            margin-right: 100px;
        }

        @media (max-width: 1024px) {
            margin-right: 0;
        }
    }

    &__icon {
        width: 28px;
        height: 28px;
        fill: $color-bg-white;

        &:hover {
            fill: $color-bg-grey;
        }
    }
}