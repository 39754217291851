@import "color-palette";

.select-input {

    &__disabled {
        background-color: $color-bg-disabled;
        border-radius: 10px;
        color: $color-text-grey;
        cursor: not-allowed;
    }
}